<template>
   <div>
      <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
         <template v-slot:item="{ item }">
            <v-breadcrumbs-item
               href="#"
               :disabled="item.disabled"
               >
               {{ item.text }}
            </v-breadcrumbs-item>
         </template>
      </v-breadcrumbs>
      <v-card class="mx-auto" outlined tile color="#F8F8FF">
         <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
            <v-progress-circular
               indeterminate
               color="primary"
               ></v-progress-circular>
         </v-overlay>
         <v-card-title>Update Merchant Rewards</v-card-title>
         <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
            <v-container class="fill-height"
               fluid>
               <v-row>
                   <v-col cols="12" md="12">
                     <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="reward_details.accountid"
                        label="Account ID"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="reward_details.corporate"
                        label="Corporate"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="reward_details.emailid"
                        label="Email ID"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="reward_details.email"
                        label="Email"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-row style="border: 1px solid grey; margin: 1px">
                        <v-col cols="12" md="12"><span class="text-body-2">Merchant Rewards Details</span></v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="reward_details.operator"
                                label="Operator"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="reward_details.carpark"
                                label="Car Park"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="reward_details.carparkuserinputcode"
                                label="CP Code"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-text-field
                              dense
                              outlined
                              type="text"
                              v-model="reward_details.slotgroupid"
                              label="Slot Group ID"
                              required
                              background-color="white"
                              hide-details="auto"
                              autocomplete="off"
                              disabled
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-text-field
                              dense
                              outlined
                              type="text"
                              v-model="reward_details.slotid"
                              label="Slot ID"
                              required
                              background-color="white"
                              hide-details="auto"
                              autocomplete="off"
                              disabled
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-text-field
                              dense
                              outlined
                              type="text"
                              v-model="reward_details.advertid"
                              label="Adv ID"
                              required
                              background-color="white"
                              hide-details="auto"
                              autocomplete="off"
                              disabled
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-text-field
                              dense
                              outlined
                              type="text"
                              v-model="reward_details.reward_name.item"
                              label="Merchant Reward Name *"
                              :rules="reward_details.reward_name.rule"
                              required
                              background-color="white"
                              hide-details="auto"
                              autocomplete="off"
                              :disabled="readonly"
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-text-field
                              dense
                              outlined
                              type="text"
                              v-model="reward_details.merchant_name.item"
                              label="Merchant Name (Display On Mobile) *"
                              :rules="reward_details.merchant_name.rule"
                              required
                              background-color="white"
                              hide-details="auto"
                              autocomplete="off"
                              :disabled="readonly"
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                           <v-text-field background-color="white"
                              dense
                              outlined
                              v-model="reward_details.url.item"
                              :rules="reward_details.url.rule"
                              label="URL"
                              :disabled="readonly"
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                           <v-file-input outlined prepend-icon="" dense persistent-hint hint="Horizontal: 400 Vertical: 400" background-color="white" label="Image *" v-model="reward_details.image1.file" :rules="reward_details.image1.imgrule" type="file" accept="image/*" :disabled="readonly"></v-file-input>
                           <v-card class="mb-6" style="height: 200px; z-index: 1" outlined @drop.prevent="addDropFile4" @dragover.prevent>
                              <v-overlay
                                 :absolute="true"
                                 opacity="1"
                                 color="grey"
                                 :value="reward_details.image1.image == '' || reward_details.image1.image == undefined ? true : false"
                                 >
                                 <v-row>
                                    <v-col style="text-align: center">
                                       <p>Drag & Drop or Select Image from Folder</p>
                                       <v-icon x-large>fa fa-image</v-icon>
                                    </v-col>
                                 </v-row>
                              </v-overlay>
                              <img :src="reward_details.image1.image" :style="{'max-width': '100%', 'max-height': '100%'}" />
                              <v-overlay
                                 :absolute="true"
                                 :value="reward_details.image1.btn_loading"
                                 >
                                 <v-progress-circular indeterminate size="64"></v-progress-circular>
                              </v-overlay>
                           </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                           <v-file-input outlined prepend-icon="" dense persistent-hint hint="Horizontal: 600 Vertical: 300" background-color="white" label="Image *" v-model="reward_details.image2.file" :rules="reward_details.image2.imgrule" type="file" accept="image/*" :disabled="readonly"></v-file-input>
                           <v-card class="mb-6" style="height: 200px; z-index: 1" outlined @drop.prevent="addDropFileImage2" @dragover.prevent>
                              <v-overlay
                                 :absolute="true"
                                 opacity="1"
                                 color="grey"
                                 :value="reward_details.image2.image == '' || reward_details.image2.image == undefined ? true : false"
                                 >
                                 <v-row>
                                    <v-col style="text-align: center">
                                       <p>Drag & Drop or Select Image from Folder</p>
                                       <v-icon x-large>fa fa-image</v-icon>
                                    </v-col>
                                 </v-row>
                              </v-overlay>
                              <img :src="reward_details.image2.image" :style="{'max-width': '100%', 'max-height': '100%'}" />
                              <v-overlay
                                 :absolute="true"
                                 :value="reward_details.image2.btn_loading"
                                 >
                                 <v-progress-circular indeterminate size="64"></v-progress-circular>
                              </v-overlay>
                           </v-card>
                        </v-col>
                        <!--
                        <v-col cols="12" md="12">
                           <span class="text-body-2">Duration: {{reward_details.duration.item}} {{reward_details.slot_type.item}}</span>
                        </v-col>
                        -->
                        <v-col cols="12">
                            <v-row dense no-gutters>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="reward_details.duration.item"
                                        label="Slot Duration"
                                        hide-details="auto"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        outlined
                                        :value="reward_details.slot_type.item.charAt(0).toUpperCase() + reward_details.slot_type.item.toLowerCase().slice(1) + '(s)'"
                                        label="Slot Type"
                                        hide-details="auto"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" v-if="showstart">
                           <vc-date-picker v-if="componentloader" v-model="reward_details.start.item" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                 <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                       <div
                                          v-bind:class="[ validateStartDateMain, true ? 'v-input--is-disabled' : '' ]"
                                          class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                          <div class="v-input__control">
                                             <div class="v-input__slot white">
                                                <fieldset aria-hidden="true">
                                                   <legend :style="focused || reward_details.start.item ? {'width': '140px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                                </fieldset>
                                                <div class="v-text-field__slot">
                                                   <label for="input-startdate" v-bind:class="[ validateStartDate, true ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Merchant Reward Start Date</label>
                                                   <input
                                                   id="input-startdate"
                                                   @focus="focused = true"
                                                   @blur="focused = false"
                                                   :value="inputValue"
                                                   v-on="inputEvents"
                                                   @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                   autocomplete="off"
                                                   :disabled="true"
                                                   />
                                                </div>
                                             </div>
                                             <div class="v-text-field__details">
                                                <v-input
                                                   error
                                                   readonly
                                                   v-model="reward_details.start.item"
                                                   :rules="reward_details.start.rule"
                                                   height="0"
                                                   class="hide-input-text"
                                                   >
                                                </v-input>
                                             </div>
                                          </div>
                                       </div>
                                    </v-col>
                                 </v-row>
                              </template>
                           </vc-date-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" v-else>
                           <vc-date-picker v-if="componentloader" :disabled-dates="{start: null, end: allowedstartdate}" v-model="reward_details.start.item" @input="calculateDuration()" update-on-input popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                 <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                       <div
                                          v-bind:class="[ validateStartDateMain, readonly ? 'v-input--is-disabled' : '' ]"
                                          class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                          <div class="v-input__control">
                                             <div class="v-input__slot white">
                                                <fieldset aria-hidden="true">
                                                   <legend :style="focused || reward_details.start.item ? {'width': '140px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                                </fieldset>
                                                <div class="v-text-field__slot">
                                                   <label for="input-startdate" v-bind:class="[ validateStartDate, readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Merchant Reward Start Date</label>
                                                   <input
                                                   id="input-startdate"
                                                   @focus="focused = true"
                                                   @blur="focused = false"
                                                   :value="inputValue"
                                                   v-on="inputEvents"
                                                   @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                   autocomplete="off"
                                                   :disabled="readonly"
                                                   />
                                                </div>
                                             </div>
                                             <div class="v-text-field__details">
                                                <v-input
                                                   error
                                                   readonly
                                                   v-model="reward_details.start.item"
                                                   :rules="reward_details.start.rule"
                                                   height="0"
                                                   class="hide-input-text"
                                                   >
                                                </v-input>
                                             </div>
                                          </div>
                                       </div>
                                    </v-col>
                                 </v-row>
                              </template>
                           </vc-date-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                           <vc-date-picker v-if="componentloader" v-model="reward_details.end.item" @input="calculateDuration()" update-on-input popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                 <v-row dense>
                                    <v-col cols="12" sm="12" md="12">
                                       <div
                                          v-bind:class="[ validateEndDateMain, focusedend ? 'v-input--is-focused primary--text' : '', true ? 'v-input--is-disabled' : '' ]"
                                          class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                          <div class="v-input__control">
                                             <div class="v-input__slot white">
                                                <fieldset aria-hidden="true">
                                                   <legend :style="focusedend || reward_details.end.item ? {'width': '140px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                                </fieldset>
                                                <div class="v-text-field__slot">
                                                   <label for="input-enddate" v-bind:class="[ validateEndDate, true ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Merchant Reward End Date</label>
                                                   <input
                                                   id="input-enddate"
                                                   @focus="focusedend = true"
                                                   @blur="focusedend = false"
                                                   :value="inputValue"
                                                   v-on="inputEvents"
                                                   @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                   autocomplete="off"
                                                   :disabled="true"
                                                   />
                                                </div>
                                             </div>
                                             <div class="v-text-field__details">
                                                <v-input
                                                   error
                                                   readonly
                                                   v-model="reward_details.end.item"
                                                   :rules="reward_details.end.rule"
                                                   height="0"
                                                   class="hide-input-text"
                                                   >
                                                </v-input>
                                             </div>
                                          </div>
                                       </div>
                                    </v-col>
                                 </v-row>
                              </template>
                           </vc-date-picker>
                        </v-col>
                        <v-col cols="12" md="12">
                           <v-row>
                              <v-col cols="12" md="6">
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.createddate"
                                    label="Created Date"
                                    required
                                    background-color="white"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.updateddate"
                                    label="Updated Date"
                                    required
                                    background-color="white"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                 <!-- <span class="text-body-2">Approval Status</span> -->
                                 <v-text-field
                                    v-if="reward_details.advertid !== null"
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.status.item"
                                    label="Status"
                                    required
                                    background-color="white"
                                    :class="input_text_color"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                                 <v-text-field
                                    v-else
                                    dense
                                    outlined
                                    type="text"
                                    value="NEW"
                                    label="Status"
                                    required
                                    background-color="white"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                 <v-text-field
                                    v-if="reward_details.status.item === 'Review'"
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.reason"
                                    label="Reason"
                                    required
                                    background-color="white"
                                    :class="input_text_color"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <span class="text-body-2">Display On Mobile</span>
                                <v-switch
                                    color="success"
                                    class="col-2 pl-0"
                                    v-model="reward_details.mobile_display.item"
                                    :disabled="readonly"
                                    :hint="reward_details.mobile_display.item ? 'Available and visible on mobile' : 'Not available on mobile'"
                                    persistent-hint
                                    >
                                    <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{reward_details.mobile_display.item == 1 ? 'Enable' : 'Disable'}}</span></template>
                                    </v-switch>
                              </v-col>
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                     <span class="text-body-2">Description *</span>
                     <text-editor v-if="componentloader" v-model="reward_details.description.item" :value="reward_details.description.item" :color="'white'" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                     <v-input
                        error
                        readonly
                        v-if="componentloader"
                        v-model="reward_details.description.item"
                        :rules="reward_details.description.rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                        :disabled="readonly"
                        >
                     </v-input>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                     <span class="text-body-2">Terms & Conditions *</span>
                     <text-editor v-if="componentloader" v-model="reward_details.tnc.item" :value="reward_details.tnc.item" :color="'white'" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                     <v-input
                        error
                        readonly
                        v-if="componentloader"
                        v-model="reward_details.tnc.item"
                        :rules="reward_details.tnc.rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                        >
                     </v-input>
                  </v-col>
                  <v-col cols="12" md="12" v-if="reward_details.promo_voucher.item">
                     <v-row style="border: 1px solid grey; margin: 1px">
                        <v-col cols="12" md="12"><span class="text-body-2">Promo Voucher Info</span></v-col>
                        <v-col cols="12" sm="12" md="6">
                           <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <span class="text-body-2">Promo Voucher</span>
                                <v-switch
                                color="grey"
                                class="col-12 pl-0"
                                v-model="reward_details.promo_voucher.item"
                                :label="`${reward_details.promo_voucher.item ? 'Yes' : 'No'}`"
                                :hint="reward_details.promo_voucher.item ? 'Promo Voucher feature is enable for this Reward Slot.' : 'Promo Voucher feature is disable for this Reward Slot.'"
                                persistent-hint
                                disabled
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.total_qty.item"
                                    label="Total Quantity"
                                    :rules="reward_details.total_qty.rule"
                                    required
                                    background-color="white"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model="reward_details.remain_qty.item"
                                    label="Remain Quantity"
                                    required
                                    background-color="white"
                                    hide-details="auto"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                 <vc-date-picker v-if="componentloader" v-model="reward_details.promo_start.item" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                       <v-row dense>
                                          <v-col cols="12" sm="12" md="12">
                                             <div
                                                v-bind:class="[ validatePromoStartDateMain, readonly ? 'v-input--is-disabled' : '' ]"
                                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                                <div class="v-input__control">
                                                   <div class="v-input__slot white">
                                                      <fieldset aria-hidden="true">
                                                         <legend :style="focusedpromostart || reward_details.promo_start.item ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                                      </fieldset>
                                                      <div class="v-text-field__slot">
                                                         <label for="input-startusedate" v-bind:class="[ validatePromoStartDate, readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Promo Start Use Date</label>
                                                         <input
                                                         id="input-startusedate"
                                                         @focus="focusedpromostart = true"
                                                         @blur="focusedpromostart = false"
                                                         :value="inputValue"
                                                         v-on="inputEvents"
                                                         @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                         autocomplete="off"
                                                         :disabled="readonly"
                                                         />
                                                      </div>
                                                   </div>
                                                   <div class="v-text-field__details">
                                                      <v-input
                                                         error
                                                         readonly
                                                         v-model="reward_details.promo_start.item"
                                                         :rules="reward_details.promo_start.rule"
                                                         height="0"
                                                         class="hide-input-text"
                                                         >
                                                      </v-input>
                                                   </div>
                                                </div>
                                             </div>
                                          </v-col>
                                       </v-row>
                                    </template>
                                 </vc-date-picker>
                              </v-col>
                              <v-col cols="12" sm="12" md="6">
                                 <vc-date-picker v-if="componentloader" v-model="reward_details.promo_end.item" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                       <v-row dense>
                                          <v-col cols="12" sm="12" md="12">
                                             <div
                                                v-bind:class="[ validatePromoEndDateMain, readonly ? 'v-input--is-disabled' : '' ]"
                                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                                <div class="v-input__control">
                                                   <div class="v-input__slot white">
                                                      <fieldset aria-hidden="true">
                                                         <legend :style="focusedpromoend || reward_details.promo_end.item ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                                      </fieldset>
                                                      <div class="v-text-field__slot">
                                                         <label for="input-endusedate" v-bind:class="[ validatePromoEndDate, readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Promo End Use Date</label>
                                                         <input
                                                         id="input-endusedate"
                                                         @focus="focusedpromoend = true"
                                                         @blur="focusedpromoend = false"
                                                         :value="inputValue"
                                                         v-on="inputEvents"
                                                         @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                                         autocomplete="off"
                                                         :disabled="readonly"
                                                         />
                                                      </div>
                                                   </div>
                                                   <div class="v-text-field__details">
                                                      <v-input
                                                         error
                                                         readonly
                                                         v-model="reward_details.promo_end.item"
                                                         :rules="reward_details.promo_end.rule"
                                                         height="0"
                                                         class="hide-input-text"
                                                         >
                                                      </v-input>
                                                   </div>
                                                </div>
                                             </div>
                                          </v-col>
                                       </v-row>
                                    </template>
                                 </vc-date-picker>
                              </v-col>
                           </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                           <v-row>
                               <v-col cols="12" sm="12" md="12">
                                <v-row>
                                <v-col cols="12">
                                    <span class="text-body-2">Promo Voucher Code for Redemption</span>
                                    <v-text-field
                                        placeholder="No QR Code"
                                        filled
                                        :value="qr.qrcode"
                                        hide-details="auto"
                                        disabled
                                        ></v-text-field>
                                </v-col>
                                <v-col cols="12" v-if="qr.qrcode !== null">
                                    <qrcode-vue :value="qr.qrcode" size="250"></qrcode-vue>
                                </v-col>
                                </v-row>
                            </v-col>
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-col>
                  <v-card-actions>
                     <v-btn v-if="!readonly" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading">
                        <v-icon left x-small>fa fa-save</v-icon>
                        Save
                     </v-btn>
                     <v-btn class="mt-4" tile small color="error" type="button" :to="{ name: 'Merchant Rewards' }">
                        <v-icon left x-small>fa fa-ban</v-icon>
                        Cancel
                     </v-btn>
                     <v-menu
                        :rounded="rounded"
                        offset-y
                        v-if="reward_details.status.item != null"
                        >
                        <template v-slot:activator="{ attrs, on }">
                           <v-btn
                              v-if="!readonly"
                              color="primary"
                              outlined
                              tile
                              small
                              class="mt-4 ml-2"
                              v-bind="attrs"
                              v-on="on"
                              >
                              <v-icon left x-small>fa fa-info</v-icon>
                              Change Status
                           </v-btn>
                        </template>
                        <v-list>
                           <v-list-item
                              v-if="reward_details.status.item == 'Publish'"
                              link
                              @click="viewMore('edit')"
                              >
                              <v-list-item-title>Review</v-list-item-title>
                           </v-list-item>
                           <v-list-item
                              v-if="reward_details.status.item == 'Review'"
                              @click="viewMore('publish')"
                              link
                              >
                              <v-list-item-title>Publish</v-list-item-title>
                           </v-list-item>
                        </v-list>
                     </v-menu>
                  </v-card-actions>
               </v-row>
            </v-container>
         </v-form>
      </v-card>
      <v-snackbar
         v-model="swal.notification"
         top
         :color="swal.scolor"
         >
         {{ swal.message }}
      </v-snackbar>
      <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
      <edit :dialog="ma.modal_edit" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
      <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
   </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/ChangeRewardSlotStatus';
import tpicker from "../TimePickerComponent";
import alertbox from '../modals/Alert';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import {rule} from '../../assets/js/checkPerm';
import dpicker from "../DatePickerComponent";
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'Update Merchant Rewards',
    title: 'Update Merchant Rewards',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Reward',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Rewards',
                disabled: true,
                href: '#',
                },

                {
                text: 'Update Merchant Rewards',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            cutoffval: '',
            cutoffrule: [],
            graceval: '',
            gracerule: [],
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Day', value: 'day', width: '20%' },
                { text: 'Action', value: 'add', width: '10%' },
                { text: 'Rates', value: 'rates', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_edit: false,
            },
            form: [ 
                {carpark: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //0
                {vpname: '', rule: [] }, //1
                {vpdesc: '', rule: [] }, //2
                {tnc: '', rule: [] }, //3
                {file: '', imgrule: [], image: '', btn_loading: '', logo: '' }, //4
                {purchasecode: false, rule: [], code: '' }, //5
                {qty: null, rule: [] }, //6
                {minpurchaseqty: null, rule: [] }, //7
                {maxpurchaseqty: null, rule: [] }, //8
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //9 sell date
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //10 use date
                {vpcost: '', rule: [] }, //11
                {vptype: [{id: '', name: 'Select'}, {id: 'time', name: 'Time'}, {id: 'duration', name: 'Duration'}], rule: [], selected: '' }, //12
                {multientry: false, rule: [] }, //13
                {numofentry: '', rule: [] }, //14
                {allowedparkingdurationhours: '', rule: [] }, //15
                {entrytimestart: '', rule: [] }, //16
                {entrytimeend: '', rule: [] }, //17
                {exittimestart: '', rule: [] }, //18
                {exittimeend: '', rule: [] }, //19
                {alloweddays: [
                    {id: 'Monday', name: 'Monday'}, 
                    {id: 'Tuesday', name: 'Tuesday'}, 
                    {id: 'Wednesday', name: 'Wednesday'},
                    {id: 'Thursday', name: 'Thursday'},
                    {id: 'Friday', name: 'Friday'},
                    {id: 'Saturday', name: 'Saturday'},
                    {id: 'Sunday', name: 'Sunday'},
                    ],
                    rule: [], selected: []
                }, //20
                {displaystatus: false, rule: [] }, //21
                {allowedentrydates: [], rule: [] }, //22
                {numofparkingdays: null, rule: [] }, //23
                {operator: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //24
                {addonqty: '', rule: [] }, //25
                {active: false }, //26
            ],
            reward_details: {
                reward_name: {item: '', rule: []},
                merchant_name: {item: '', rule: []},
                mobile_display: {item: false},
                image1: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                image2: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                url: {item: '', rule: []},
                duration: {item: ''},
                slot_type: {item: ''},
                createddate: '',
                updateddate: '',
                status: {item: ''},
                description: {item: '', rule: []},
                tnc: {item: '', rule: []},
                start: {item: '', rule: []},
                end: {item: '', rule: []},
                promo_start: {item: '', rule: []},
                promo_end: {item: '', rule: []},
                promo_voucher: {item: false},
                total_qty: {item: '', rule: []},
                remain_qty: {item: ''},
                reason: '',
                carparkuserinputcode: '',
                slotgroupid: '',
                slotid: '',
                advertid: '',
                operator: '',
                carpark: '',
                accountid: '',
                corporate: '',
                emailid: '',
                email: ''
            },
            displayitems: {
                createddate: '',
                updateddate: '',
                vpid: '',
                totalsold: '',
                totalunsold: '',
                utilizetype: {
                    selected: '',
                    items: [
                        {name: 'Select', id: ''},
                        {name: 'Per Entry Per Day', id: '1'},
                        {name: 'Per Entry Multiple Days', id: '2'},
                        {name: 'Multiple Entries Per Day', id: '3'},
                        {name: 'Multiple Entries Multiple Days', id: '4'},
                    ],
                    rule: [],
                    loader: false
                }
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,
            firstloadeditems: [],
            readonly: false,
            allowedstartdate: new Date(moment(new Date()).subtract('1', 'days')),
            focused: false,
            focusedend: false,
            focusedpromostart: false,
            focusedpromoend: false,
            qr: {
                items: [],
                qrcode: ''
            },
            showstart: false,
            input_text_color: 'black-text'
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor, tpicker, dpicker, QrcodeVue},

    async created(){
        this.$vuetify.goTo(0);
        if (!this.validateAccess('merchantrewards_update')){
            this.readonly = true;
        }
        await this.getItems();
        this.$watch(() => this.reward_details.image1.file, this.handleImageMain, {deep: true});
        this.$watch(() => this.reward_details.image2.file, this.handleImageMain2, {deep: true});
    },

    async beforeRouteLeave (to, from , next) {
        let a = JSON.stringify(this.firstloadeditems) === JSON.stringify(this.form);
        if (this.ma.modal_action_type === ''){
            if (!a){
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
                if (answer) {
                    next()
                } else {
                    next(false);
                }
            }

            else {
                next();
            }
        }

        else{
            next();
        }
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.reward_details.start.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.start.item == '' && this.reward_details.start.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.start.item == '' && this.reward_details.start.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        validateEndDate(){
            let classes = '';
            if (this.focusedend || this.reward_details.end.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.end.item == '' && this.reward_details.end.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateEndDateMain(){
            let classes = '';
            if (this.focusedend){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.end.item == '' && this.reward_details.end.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        /////
        
        validatePromoStartDate(){
            let classes = '';
            if (this.focusedpromostart || this.reward_details.promo_start.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.promo_start.item == '' && this.reward_details.promo_start.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validatePromoStartDateMain(){
            let classes = '';
            if (this.focusedpromostart){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.promo_start.item == '' && this.reward_details.promo_start.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        validatePromoEndDate(){
            let classes = '';
            if (this.focusedpromoend || this.reward_details.promo_end.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.promo_end.item == '' && this.reward_details.promo_end.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validatePromoEndDateMain(){
            let classes = '';
            if (this.focusedpromoend){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.promo_end.item == '' && this.reward_details.promo_end.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getItems(pg){
            try{
                this.overlay = true;
                let response = await this.$store.dispatch("getMerchantRewardSlot", {page: 1, slotid: Number(this.$route.params.id), loginemailid: Number(this.$route.params.loginemailid)});

                if (response.data.code == 'AP000'){
                    let dt = response.data.record;

                    for(var i=0; i<dt.length; i++){
                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        dt[i].displaystatus = dt[i].MobileDisplayStatus == true ? 'Yes' : 'No';
                        dt[i].DateCreated = moment(new Date(dt[i].DateCreated)).format('DD-MM-YYYY hh:mm:ss A');
                        //replace(/\s/g,'') remove any literal whitespace
                    }

                    this.items = dt;
                    this.pages = Math.ceil(5 / 100); //to update

                    if (this.items[0].Approval_Status == 'review'){
                        let response2 = await this.$store.dispatch("getReviewItems", {});
                        let dt2 = response2.data.record;
                        let ritems = dt2.find(itms => itms.reviewid == this.items[0].ReviewID);
                        this.reward_details.reason = ritems.description;
                        this.input_text_color = "red-text";
                    }

                    let startselldate = this.items[0].Ad_Start_Date == null ? "" : new Date(moment.utc(new Date(new Date(this.items[0].Ad_Start_Date))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
                    //startselldate.setHours(0,0,0,0);

                    let endselldate = this.items[0].Ad_End_Date == null ? "" : new Date(moment.utc(new Date(new Date(this.items[0].Ad_End_Date))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
                    //endselldate.setHours(23,59,59,0);

                    let startpromodate = this.items[0].PromoUseStartDate == null ? "" : new Date(moment.utc(new Date(new Date(this.items[0].PromoUseStartDate))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
                    //startselldate.setHours(0,0,0,0);

                    let endpromodate = this.items[0].PromoUseEndDate == null ? "" : new Date(moment.utc(new Date(new Date(this.items[0].PromoUseEndDate))).tz('Asia/Kuala_Lumpur').format('MM/DD/YYYY hh:mm:ss A'));
                    //endselldate.setHours(23,59,59,0);

                    this.form[0].selected = this.items[0].CarParkID;
                    this.form[24].selected = this.items[0].OperatorID;

                    this.reward_details.start.item = startselldate;
                    this.reward_details.end.item = endselldate;
                    this.showstart = this.items[0].Ad_Start_Date != null && this.reward_details.start.item < new Date();

                    this.reward_details.promo_start.item = startpromodate;
                    this.reward_details.promo_end.item = endpromodate;

                    this.reward_details.image1.image = this.items[0].Ad_Image_1;
                    this.reward_details.image1.file = '';

                    this.reward_details.image2.image = this.items[0].Ad_Image_2;
                    this.reward_details.image2.file = '';

                    this.reward_details.duration.item = this.items[0].PurchaseDurationPerSlot;
                    this.reward_details.slot_type.item = this.items[0].PurchaseDurationUnit;

                    this.reward_details.reward_name.item = this.items[0].Ad_Title;
                    this.reward_details.merchant_name.item = this.items[0].MerchantName;
                    this.reward_details.url.item = this.items[0].URL == null ? "" : this.items[0].URL;
                    this.reward_details.mobile_display.item = this.items[0].MobileDisplayStatus;

                    this.reward_details.createddate = this.items[0].DateCreated;
                    this.reward_details.updateddate = this.items[0].DateUpdated == null ? "N/A" : moment(new Date(this.items[0].DateUpdated)).format('DD-MM-YYYY hh:mm:ss A');

                    this.reward_details.status.item = this.items[0].Approval_Status == null ? this.items[0].Approval_Status : this.items[0].Approval_Status.charAt(0).toUpperCase() + this.items[0].Approval_Status.toLowerCase().slice(1);

                    this.reward_details.description.item = this.items[0].Ad_Description;
                    this.reward_details.tnc.item = this.items[0].TNC;

                    this.reward_details.promo_voucher.item = this.items[0].HasRedeemablePromo;
                    this.qr.qrcode = this.items[0].PromoCode;
                    this.reward_details.total_qty.item = this.items[0].TotalPromoQuantity;
                    this.reward_details.remain_qty.item = this.items[0].PromoQuantityLeft;
                    this.reward_details.carparkuserinputcode = this.items[0].CarParkUserInputCode;
                    this.reward_details.slotgroupid = this.items[0].SlotGroupID;
                    this.reward_details.slotid = this.items[0].SlotID;
                    this.reward_details.advertid = this.items[0].AdvertID;
                    this.reward_details.operator = `${this.items[0].OperatorID} - ${this.items[0].OperatorName}`;
                    this.reward_details.carpark = `${this.items[0].CarParkID} - ${this.items[0].CarParkName}`;
                    this.reward_details.accountid = this.items[0].CorporateAccountID;
                    this.reward_details.corporate = this.items[0].CorporateName;
                    this.reward_details.emailid = this.items[0].loginEmailID;
                    this.reward_details.email = this.items[0].linkedemail;

                    this.firstloadeditems = JSON.parse(JSON.stringify(this.form));
                    this.componentloader = true;
                    this.overlay = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async updateMerchantRewardSlot(){
            this.btn_loading = true;
            this.loading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "loginemailid": this.items[0].loginEmailID,
                    "slotid": this.items[0].SlotID,
                    "contactemail": this.items[0].linkedemail,
                    "contactnumber": this.items[0].ContactNumber,
                    "merchantname": this.reward_details.merchant_name.item,
                    "promousestartdate": this.reward_details.promo_start.item == null || this.reward_details.promo_start.item == "" ? null : moment(new Date(this.reward_details.promo_start.item).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm'),
                    "promouseenddate": this.reward_details.promo_end.item == null || this.reward_details.promo_end.item == "" ? null : moment(new Date(this.reward_details.promo_end.item).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm'),
                    "adtitle": this.reward_details.reward_name.item,
                    "addescription": this.reward_details.description.item,
                    "adtnc": this.reward_details.tnc.item,
                    "url": this.reward_details.url.item,
                    "adstartdate": moment(new Date(this.reward_details.start.item).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm'),
                    "adenddate": moment(new Date(this.reward_details.end.item).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm'),
                    "image1": this.reward_details.image1.file == "" || this.reward_details.image1.file == undefined ? null : this.reward_details.image1.image,
                    "image2": this.reward_details.image2.file == "" || this.reward_details.image2.file == undefined ? null : this.reward_details.image2.image
                };
                let response = await this.$store.dispatch("updateMerchantRewardSlot", req);
                if (response.data.code === 'AP000'){
                    await this.updateMerchantRewardSlotMobileStatus();
                    this.$router.push({ name: 'Merchant Rewards', query: {updated: true, id: this.items[0].loginEmailID, slotid: this.items[0].SlotID, appstatus: 'update', operatorid: this.items[0].OperatorID, carparkid: this.items[0].CarParkID}});
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.loading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        async changeApprovalStatus(v){
            let appstatus = '';
            let req;
            if (this.reward_details.status.item == 'Publish')
            {
                appstatus = 'review';
                req = {
                    loginemailid: this.items[0].loginEmailID,
                    slotid: this.items[0].SlotID,
                    approvalstatus: appstatus,
                    reviewid: v.reason
                };
            }
            else {
                appstatus = 'publish';
                req = {
                    loginemailid: this.items[0].loginEmailID,
                    slotid: this.items[0].SlotID,
                    approvalstatus: appstatus,
                    reviewid: this.items[0].ReviewID
                };
            }

            try{
                let response = await this.$store.dispatch("changeApprovalStatus", req);
                //await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    this.$router.push({ name: 'Merchant Rewards', query: {updated: true, id: this.items[0].loginEmailID, slotid: this.items[0].SlotID, appstatus: appstatus}});
                }
                
                else {
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to change status`;
                }
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        async updateMerchantRewardSlotMobileStatus(){
            try{
                let req = {
                    slotid: this.items[0].SlotID,
                    mobiledisplaystatus: this.reward_details.mobile_display.item
                };
                let response = await this.$store.dispatch("updateMerchantRewardSlotMobileStatus", req);
                //await timer.sleep(800);
                if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to change status`;
                }
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(flag){
            if (flag == 'edit'){
                this.ma.modal_edit = true;
            }

            if (flag == 'publish' || flag == 'update'){
                this.ma.alertmodal = true;
            }

            this.ma.customText = {text1: this.items[0].SlotID};
            this.ma.modal_action_type = flag;
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_edit = value;
                this.ma.alertmodal = value;
            }
        },

        onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.confirmCreate = true;
                this.ma.alertmodal = false;

                if (this.ma.modal_action_type === 'publish')
                this.changeApprovalStatus();

                if (this.ma.modal_action_type === 'update')
                this.updateMerchantRewardSlot();
            }

            if (value.flag == 'edit'){
                this.ma.modal_edit = false;
                this.changeApprovalStatus(value);
            }
        },

        onRuleChange() {
            this.reward_details.tnc.rule = []; this.reward_details.description.rule = []; this.reward_details.image1.rule = []; this.reward_details.image2.rule = [];
            this.reward_details.reward_name.rule = [];
            this.reward_details.merchant_name.rule = [];
        },

        async beforeCreateForm(flag){
            console.log('start', this.form[9].range.start);
            this.onRuleChange();
                
            let qtymax = this.form[6].qty !== null && Number(this.form[8].maxpurchaseqty) >= Number(this.form[6].qty);
            let qtymin = this.form[6].qty !== null && Number(this.form[7].minpurchaseqty) >= Number(this.form[6].qty);
            let qtymin2 = this.form[6].qty !== null && Number(this.form[7].minpurchaseqty) > Number(this.form[8].maxpurchaseqty);
            let multientrycheck = this.form[13].multientry && this.form[14].numofentry === '';
            let imagecheck = this.reward_details.image1.image === '' || this.reward_details.image1.image === null;
            let imagecheck2 = this.reward_details.image2.image === '' || this.reward_details.image2.image === null;
            let checkpromovoucher = this.reward_details.promo_voucher.item;

            this.reward_details.reward_name.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.merchant_name.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.description.rule = [
                v => !!v || 'Description is required',
            ];

            this.reward_details.tnc.rule = [
                v => !!v || 'Terms & Conditions are required',
            ];

            this.reward_details.image1.imgrule = [
                v => !imagecheck || 'Image is required',
            ];

            this.reward_details.image2.imgrule = [
                v => !imagecheck2 || 'Image is required',
            ];

            this.reward_details.start.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.promo_start.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.promo_end.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.url.rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.reward_details.image1.btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.reward_details.image1.image = base64img;
            this.reward_details.image1.btn_loading = false;
            let check = await this.getImageDimensions(this.reward_details.image1.image, 400, 400);
            if (!check){
                this.reward_details.image1.image = '';
                this.reward_details.image1.file = '';
                this.reward_details.image1.imgrule = [
                    v => false || 'Please ensure image is 400 x 400'
                ];
                console.log(this.reward_details.image1.imgrule);
            }

            else{
                this.reward_details.image1.imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.reward_details.image1.image = this.items[0].Ad_Image_1;
                this.reward_details.image1.btn_loading = false;
                });
            }
        },

        async handleImageMain2(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.reward_details.image2.btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.reward_details.image2.image = base64img;
            this.reward_details.image2.btn_loading = false;
            let check = await this.getImageDimensions(this.reward_details.image2.image, 600, 300);
            if (!check){
                this.reward_details.image2.image = '';
                this.reward_details.image2.file = '';
                this.reward_details.image2.imgrule = [
                    v => false || 'Please ensure image is 600 x 300'
                ];
                console.log(this.reward_details.image2.imgrule);
            }

            else{
                this.reward_details.image2.imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.reward_details.image2.image = this.items[0].Ad_Image_2;
                this.reward_details.image2.btn_loading = false;
                });
            }
        },

        getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        addDropFile4(e) { this.reward_details.image1.file = e.dataTransfer.files[0]; },
        addDropFileImage2(e) { this.reward_details.image2.file = e.dataTransfer.files[0]; },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
            }
        },

        calculateDuration(){
            let startselldate = moment(new Date(new Date(this.reward_details.start.item))).utc().format('YYYY-MM-DD');
            let endselldate = moment(new Date(new Date(this.reward_details.end.item))).utc().format('YYYY-MM-DD');

            let slot_type = "";
            let days = 0;
            if (this.reward_details.slot_type.item == "month") slot_type = 29;
            if (this.reward_details.slot_type.item == "week") slot_type = 6;
            if (this.reward_details.slot_type.item == "year") slot_type = 364;

            days = this.reward_details.duration.item * slot_type;
            console.log('days', days);

            this.reward_details.end.item = new Date(moment(this.reward_details.start.item).add(days, 'days'));
        },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },
    }
}
</script>